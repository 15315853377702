import ClientSuspense from '@components/ClientSuspense'
import Loading from '@components/SuspenseLoading/Loading'
import {useUserPreferencesPartial} from '@data/queries/preferences/userPreferences.main'
import {useWebsiteMenuInformation} from '@data/queries/website/websiteMenuInformation.main'
import isServerSide from '@helpers/misc/isServerSide'
import useIsStore from '@hooks/useIsStore'
import useProductsLayout from '@page-components/Order/useProductsLayout'
import dynamic from 'next/dynamic'

const LongOrCompactCPG = dynamic(() => import('@page-components/Order/LongOrCompactCPG'))
const ProductsLayout = dynamic(() => import('@page-components/Order/Products'))
const CategoriesLayout = dynamic(() => import('@page-components/Order/CategoriesLayout'))
const NoMenu = dynamic(() => import('@page-components/Order/NoMenu'))

export default function Products() {
  const isStore = useIsStore()
  const {website} = useWebsiteMenuInformation()
  const userPreferences = useUserPreferencesPartial(website._id, {skip: isServerSide()})
  const productsLayout = useProductsLayout(website)

  if (
    !userPreferences?.data?.userPreferences?.menuId &&
    !userPreferences.loading &&
    !isServerSide()
  ) {
    return <NoMenu />
  }

  if (productsLayout === 'categories') return <CategoriesLayout />

  if (isStore) {
    return (
      <ClientSuspense fallback={<Loading />}>
        <LongOrCompactCPG />
      </ClientSuspense>
    )
  }

  return <ProductsLayout />
}
